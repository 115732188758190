import {RouteAnaliseGuard} from './security/route.analise.guard';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {AppComponent} from './app.component';
import {HeaderComponent} from './view/header/header.component';
import {RouterModule} from '@angular/router';
import {ROUTES} from './app.routes';
import {ChangeBodyIdDirective} from './diretiva/change-body-id.directive';
import {ValidateEqualDirective} from './diretiva/validate-equal.directive'
import {UsuarioService} from './service/usuario.service';
import {NotFoundComponent} from './view/not-found/not-found.component';
import {ApplicationErrorHandler} from './app.error-handler';
import {NotificationService} from './service/notification.service';
import {ComboService} from './service/combo.service';
import {LoginService} from './service/login.service';
import {LoggedInGuard} from './security/loggedin.guard';
import {SharedModule} from './module/shared.module';
import {HomeModule} from './module/home.module';
import {AuthInterceptor} from './security/auth.interceptor';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NotificacaoService} from './service/notificacao.service';
import {DownloadFileService} from './service/download-file.service';
import {IndicadorService} from './service/indicadores.service';
import {InvestigacaoService} from './service/investigacao.service';
import {UserIdleModule} from 'angular-user-idle';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {SetoresService} from './service/setores.service';
import {AuthorizeService} from './service/authorize.service';
import {SortService} from './shared/sortable-column/sort.service';
import {VersionService} from './service/version.service';
import {VinculacaoService} from './service/vinculacao.service';
import {ManageUserService} from './service/manage.user.service';
import {RouteInvestigadorGuard} from './security/route.investigador.guard';
import {RouteCrudAdminGuard} from './security/route.crud.admin.guard';
import {RouteCrudGestaoGuard} from './security/route.crud.gestao.guard';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {QuillModule} from 'ngx-quill';
import {PipesModule} from './pipes/pipes.module';
import {AvisoService} from './service/aviso.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ChangeBodyIdDirective,
    ValidateEqualDirective,
    NotFoundComponent,
  ],
  imports: [
    AutocompleteLibModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    SharedModule,
    HomeModule,
    HttpClientModule,
    MatAutocompleteModule,
    NgxSmartModalModule.forRoot(),
    RouterModule.forRoot(ROUTES),
    UserIdleModule.forRoot({idle: 1500, timeout: 60, ping: 1680}),
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDialogModule,
    QuillModule.forRoot(),
  ],
  providers: [
    UsuarioService,
    ManageUserService,
    LoginService,
    NotificacaoService,
    NotificationService,
    InvestigacaoService,
    DownloadFileService,
    AvisoService,
    IndicadorService,
    ComboService,
    VersionService,
    VinculacaoService,
    SortService,
    SetoresService,
    AuthorizeService,
    LoggedInGuard,
    RouteAnaliseGuard,
    RouteInvestigadorGuard,
    RouteCrudAdminGuard,
    RouteCrudGestaoGuard,
    InvestigacaoService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'pt-BR'},
    {provide: ErrorHandler, useClass: ApplicationErrorHandler}
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
