import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CategoriaProfissional } from "../model/categoria-profissional.model";
import { Observable } from "rxjs";
import { SIGIH_API } from "../app.api";
import { Hospital } from "../model/hospital.model";
import { Setor } from "../model/setor.model";
import { Leito } from "../model/leito.model";
import { TipoEnvolvido } from "../model/tipo-envolvido.model";
import { TipoTrabalhadorTerceirizado } from "../model/tipo-trabalhador-terceirizado.model";
import { TipoIncidente } from "../model/tipo-incidente.model";
import { TipoAssistencia } from "../model/tipo-assistencia.model";
import { Incidente } from "../model/Incidente.model";
import { TipoDano } from "../model/tipo-dano.model";
import { TipoProblemaSangue } from "../model/tipo-problema-sangue.model";
import { TipoMaterialHospitalar } from "../model/tipo-material-hospitalar.model";
import { Exportacao } from "../model/exportacao.model";
import Paciente from "../model/paciente.model";
import { SetoresService } from "./setores.service";
import { UnidadeSaude } from "../model/unidade-saude.model";
import { Metas } from "../model/metas.model";
import { Administrativo } from "../model/administrativo";
import { Comunicacao } from "../model/comunicacao";
import { Equipamento } from "../model/equipamento.model";
import { MaterialMedico } from "../model/material-medico.model";

@Injectable()
export class ComboService {
  constructor(private http: HttpClient, private setorService: SetoresService) {}

  getCategoriasProfissional(): Observable<CategoriaProfissional[]> {
    return this.http.get<CategoriaProfissional[]>(
      `${SIGIH_API}/professionalCategories`
    );
  }

  getHospitais(): Observable<UnidadeSaude[]> {
    return this.http.get<UnidadeSaude[]>(`${SIGIH_API}/hospitals`);
  }
  getSetores(
    hospitalId: number,
    ac1: string,
    ac2: string
  ): Observable<Setor[]> {
    let tpSectors = new Array();
    tpSectors.push(ac1, ac2);

    return this.setorService.findSetores(null, "false");
  }

  getLeitos(setorId: number): Observable<Leito[]> {
    return this.setorService.findLeitos(setorId, false);
  }

  getEnvolvidos(): Observable<TipoEnvolvido[]> {
    return this.http.get<TipoEnvolvido[]>(`${SIGIH_API}/involved/type`);
  }

  getTiposTrabalhadorTerceirizado(): Observable<TipoTrabalhadorTerceirizado[]> {
    return this.http.get<TipoTrabalhadorTerceirizado[]>(
      `${SIGIH_API}/outsourcedTypes`
    );
  }

  getTiposIncidente(): Observable<TipoIncidente[]> {
    return this.http.get<TipoIncidente[]>(`${SIGIH_API}/incidents/types`);
  }

  getTiposAssistenciaParent(): Observable<TipoAssistencia[]> {
    return this.http.get<TipoAssistencia[]>(`${SIGIH_API}/care/type/parent`);
  }

  getTiposAssistenciaByParent(id: number): Observable<TipoAssistencia[]> {
    return this.http.get<TipoAssistencia[]>(
      `${SIGIH_API}/care/type/parent/${id}`
    );
  }

  getTiposIncidentes(): Observable<Incidente[]> {
    return this.http.get<Incidente[]>(`${SIGIH_API}/incidents/types`);
  }

  getTiposDano(): Observable<TipoDano[]> {
    return this.http.get<TipoDano[]>(`${SIGIH_API}/damage/type`);
  }

  getTiposHemovigilancia(): Observable<TipoProblemaSangue[]> {
    return this.http.get<TipoProblemaSangue[]>(
      `${SIGIH_API}/bloodProblem/type`
    );
  }

  getCategoriaFarmacovigilancia(): Observable<CategoriaProfissional[]> {
    return this.http.get<CategoriaProfissional[]>(
      `${SIGIH_API}/drugReaction/type`
    );
  }

  getTiposFarmacovigilancia(id: number): Observable<CategoriaProfissional[]> {
    return this.http.get<CategoriaProfissional[]>(
      `${SIGIH_API}/drugReaction/type/categoria/${id}`
    );
  }

  getTipoMaterialHospitalar(): Observable<MaterialMedico[]> {
    return this.http.get<MaterialMedico[]>(
      `${SIGIH_API}/hospitals/material/type`
    );
  }

  getTipoProblemaMaterialHospitalar(): Observable<any[]> {
    return this.http.get<any[]>(
      `${SIGIH_API}/hospitals/material/problem/type`
    );
  }

  getTipoEquipamento(): Observable<Equipamento[]> {
    return this.http.get<Equipamento[]>(`${SIGIH_API}/equipment/type`);
  }

  getTipoProblemaEquipamento(): Observable<TipoMaterialHospitalar[]> {
    return this.http.get<TipoMaterialHospitalar[]>(
      `${SIGIH_API}/equipment/problem/type`
    );
  }

  getTiposExports(): Observable<Exportacao[]> {
    return this.http.get<Exportacao[]>(`${SIGIH_API}/export/all`);
  }

  getAnos(): Observable<number[]> {
    return this.http.get<number[]>(`${SIGIH_API}/indicator/anual`);
  }

  getPeriodo(periodo: string, ano: number): Observable<number[]> {
    if (periodo == "anual") {
      return this.http.get<number[]>(`${SIGIH_API}/indicator/${periodo}`);
    }
    return this.http.get<number[]>(`${SIGIH_API}/indicator/${periodo}/${ano}`);
  }

  getPacientes(): Observable<Paciente[]> {
    return this.http.get<Paciente[]>(`${SIGIH_API}/patients`);
  }

  getTipoMetas(): Observable<Metas[]> {
    return this.http.get<Metas[]>(`${SIGIH_API}/metas`);
  }

  getTipoAdministrativo(): Observable<Administrativo[]> {
    return this.http.get<Administrativo[]>(`${SIGIH_API}/administrativo/type`);
  }

  getTipoComunicacao(): Observable<Comunicacao[]> {
    return this.http.get<Comunicacao[]>(`${SIGIH_API}/comunicacao/type`);
  }
}
