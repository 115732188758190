export default class JsonSanitize {


  public static sanitize(json: any) {
    if (!json) {
      return null;
    }
    const cleanedObj: Record<string, any> = {};
    Object.keys(json).forEach(key => {
      const value = json[key];
      if (value !== undefined && value !== null && typeof value !== 'function' && value !== '' && key !== 'lista') {
        cleanedObj[key] = value;
      }
    });
    return cleanedObj;
  }

  public static serialize(obj: any) {
    if (!obj) {
      return '';
    }
    const _obj = JsonSanitize.sanitize(obj);
    if (!_obj) {
      return '';
    }
    return new URLSearchParams(_obj).toString();
  }

}
