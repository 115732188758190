import {NgModule} from '@angular/core';
import {RadioComponent} from '../shared/radio/radio.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SnackbarComponent} from '../shared/message/snackbar/snackbar.component';
import {InputComponent} from '../shared/input/input.component';
import {NgxMaskModule} from 'ngx-mask';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {EscapeHtmlPipe} from '../pipes/keep-html.pipe';
import {CustomFormsModule} from 'ngx-custom-validators';
import {NgxSpinnerModule} from 'ngx-spinner'
import {NotificationDetailComponent} from '../shared/notification-detail/notification-detail.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {SortableColumnComponent} from '../shared/sortable-column/sortable-column.component';
import {FieldComponent} from '../shared/field/field.component';

@NgModule({
  declarations: [SortableColumnComponent, RadioComponent, SnackbarComponent, EscapeHtmlPipe, FieldComponent, InputComponent, NotificationDetailComponent],
  imports: [CommonModule, FormsModule, FormsModule, CustomFormsModule, BsDatepickerModule.forRoot(),
    NgxSpinnerModule, TimepickerModule.forRoot(), NgxMaskModule.forRoot(), ModalModule.forRoot(),
    NgxPaginationModule],
  exports: [SortableColumnComponent, RadioComponent, SnackbarComponent, CommonModule, InputComponent,
    FormsModule, BsDatepickerModule, EscapeHtmlPipe, ModalModule,
    FieldComponent,
    TimepickerModule, NgxMaskModule, CustomFormsModule,
    NgxSpinnerModule, NotificationDetailComponent, NgxPaginationModule]
})
export class SharedModule {

}
