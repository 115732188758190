import {AfterContentInit, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sig-field',
  templateUrl: './field.component.html'
})
export class FieldComponent {

  @Input()
  public className: string;

  @Input()
  public label: string;

  @Input()
  public required: boolean;

  @Input()
  public isValid: boolean;

  @Input()
  public errorMessage: string = 'Campo obrigatório.';
}
