import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UnidadeSaude} from '../model/unidade-saude.model';
import {SIGIH_API} from '../app.api';
import {RelUnidadeSaudeSetorDTO} from '../model/rel-unidade-saude-setor';
import JsonSanitize from '../shared/utils/json-sanitize.util';

@Injectable({
  providedIn: 'root',
})
export class AvisoService {
  constructor(private http: HttpClient) {
  }

  search(filter: any): Observable<any> {
    const serialized = JsonSanitize.serialize(filter);
    return this.http.get<any>(`${SIGIH_API}/avisos/search?${serialized}`);
  }

  create(model: any): Observable<any> {
    return this.http.post<any>(`${SIGIH_API}/avisos`, model);
  }

  findById(id: number): Observable<any> {
    return this.http.get<any>(`${SIGIH_API}/avisos/${id}`);
  }

  disabled(id: any) {
    return this.http.put<any>(`${SIGIH_API}/avisos/${id}/disable`, {});
  }
}
